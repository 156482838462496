define("ember-cropster-common/helpers/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    breadCrumbs: Ember.inject.service(),
    _breadCrumb: null,
    init: function init() {
      var _this = this;

      this._super();

      this._breadCrumb = {}; // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly

      Ember.run.scheduleOnce('afterRender', function () {
        _this.breadCrumbs.add(_this._breadCrumb);
      });
    },
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          title = _ref2[0],
          model = _ref2[1];

      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var routeName = this._getRouteName(hash);

      (false && !(routeName) && Ember.assert('the {{bread-crumb}} helper needs a routeName - it can only automatically look it up in a route template.', routeName));
      var _breadCrumb = this._breadCrumb; // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly

      Ember.run.scheduleOnce('afterRender', function () {
        Ember.set(_breadCrumb, 'title', title);
        Ember.set(_breadCrumb, 'route', routeName);
        Ember.set(_breadCrumb, 'model', model);
      });
    },
    _getRouteName: function _getRouteName(_ref3) {
      var routeName = _ref3.routeName,
          context = _ref3.context;

      if (routeName) {
        return routeName;
      } // Context is usually the controller
      // This is set by an AST plugin (see libs/ast-plugins/add-context-to-bread-crumbs)
      // All Ember objects have a _debugContainerKey property, which is the DI lookup path, like controller:roast/overview/index
      // This is an intimate API, but it has been around for a long time, and is used by other big addons as well,
      // like ember-component-css or ember-functional-css
      // If this at one point stops working, the tests will immediately fail due to the assertion in `compute()`


      var contextKey = context._debugContainerKey || ''; // If the context is not a controller, we return an empty string

      if (contextKey.indexOf('controller:') !== 0) {
        return '';
      } // Else, we use the controller name as route


      var controllerName = contextKey.replace('controller:', '');
      return controllerName.replace(/\//g, '.');
    },
    destroy: function destroy() {
      var _this2 = this;

      // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly
      Ember.run.scheduleOnce('afterRender', function () {
        return _this2.breadCrumbs.remove(_this2._breadCrumb);
      });
    }
  });

  _exports.default = _default;
});