define("ember-cropster-common/services/toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Show toast messages in your app.
   * You need to add `{{toast-container}} to your application template in order to use this!
   *
   * Usage:
   * ```js
   * toast.info('message');
   * toast.info('message', 'title');
   * toast.info('message', 'title', { timeOut: 0 });
   * toast.success('message');
   * toast.warning('message');
   * let toastItem = toast.error('message');
   * toast.remove(toastItem);
   * toast.clear(); // remove all
   * ```
   *
   * The following options are allowed:
   *
   * ```js
   * {
   *   timeOut: 0, // no timeout, or ms
   *   toastClass: '',
   *   preventDuplicates: true,
   *   onClick: null // can be a function to call when the user clicks on the toast (=closes it)
   * }
   * ```
   *
   * @namespace Service
   * @class Toast
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    toasts: null,

    /**
     * Show a success message.
     *
     * @method success
     * @param {String} message
     * @param {String} title
     * @param {Object} options
     * @return {Object}
     */
    success: function success() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (arguments.length === 2 && _typeof(title) === 'object') {
        options = title;
        title = '';
      }

      return this._addMessage(message, title, 'success', options);
    },

    /**
     * Show an info message.
     *
     * @method info
     * @param {String} message
     * @param {String} title
     * @param {Object} options
     * @return {Object}
     */
    info: function info() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (arguments.length === 2 && _typeof(title) === 'object') {
        options = title;
        title = '';
      }

      return this._addMessage(message, title, 'info', options);
    },

    /**
     * Show a warning message.
     *
     * @method warning
     * @param {String} message
     * @param {String} title
     * @param {Object} options
     * @return {Object}
     */
    warning: function warning() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (arguments.length === 2 && _typeof(title) === 'object') {
        options = title;
        title = '';
      }

      return this._addMessage(message, title, 'warning', options);
    },

    /**
     * Show an error message.
     *
     * @method error
     * @param {String} message
     * @param {String} title
     * @param {Object} options
     * @return {Object}
     */
    error: function error() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (arguments.length === 2 && _typeof(title) === 'object') {
        options = title;
        title = '';
      }

      return this._addMessage(message, title, 'error', options);
    },

    /**
     * Remove all toasts.
     *
     * @method removeAllToasts
     * @public
     */
    removeAllToasts: function removeAllToasts() {
      this.toasts.clear();
    },

    /**
     * Remove a single toast.
     * This expects the result of e.g. `toast.info()` as argument.
     *
     * @method removeToast
     * @param {Object} toastItem
     * @public
     */
    removeToast: function removeToast(toastItem) {
      (false && !(toastItem) && Ember.assert('You must specify a toast item for `toast.removeToast()`', toastItem));
      this.toasts.removeObject(toastItem);
    },
    _addMessage: function _addMessage() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var type = arguments.length > 2 ? arguments[2] : undefined;
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(this._isTesting || document.querySelectorAll('[data-toast-container]').length === 1) && Ember.assert('You need to add the `{{toast-container}}` component to your application template.', this._isTesting || document.querySelectorAll('[data-toast-container]').length === 1));
      var toasts = this.toasts;

      if (options.preventDuplicates) {
        var exitingToast = toasts.find(function (toast) {
          return toast.message === message && toast.title === title;
        });

        if (exitingToast) {
          return exitingToast;
        }
      }

      var toastItem = {
        message: message,
        title: title,
        options: options,
        type: type
      };
      toasts.pushObject(toastItem);
      return toastItem;
    },
    _isTesting: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),
    // Overwrite this e.g. if you want translated labels
    removeAllKeyboardLabel: 'Remove all toast messages',
    focusFirstKeyboardLabel: 'Focus first toast message',
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'toasts', []);
    }
  });

  _exports.default = _default;
});