define("ember-cropster-models-table/mixins/server-paginated-table-controller", ["exports", "ember-cropster-common/utils/eq-object-content"], function (_exports, _eqObjectContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin should be used on a controller that contains a c-table-paginated component.
   *
   * @namespace EmberCropsterModelsTable.Mixin
   * @class ServerPaginatedTableController
   * @public
   */
  var _default = Ember.Mixin.create({
    userSettings: Ember.inject.service(),
    queryParams: ['sort', 'sortOrder', 'filters', 'page', {
      pageSizeOverwrite: 'pageSize'
    }],

    /**
     * The table ID to use for tracking & remembering the columns.
     *
     * @property tableId
     * @type {String}
     * @public
     */
    tableId: null,

    /**
     * The initial page.
     *
     * @property page
     * @type {Number}
     * @default 1
     * @public
     */
    page: 1,

    /**
     * The initial/default property to sort by.
     *
     * @property sort
     * @type {String}
     * @default 'id'
     * @public
     */
    sort: 'id',

    /**
     * The initial/default sort direction.
     *
     * @property sortOrder
     * @type {'ASC'|'DESC'}
     * @default 'ASC'
     * @public
     */
    sortOrder: 'ASC',

    /**
     * The page size to use. This overwrites the default page size.
     *
     * @property pageSizeOverwrite
     * @type {Number}
     * @default null
     * @public
     */
    pageSizeOverwrite: null,

    /**
     * The default filters to use.
     *
     * @property filters
     * @type {Object}
     * @default {}
     * @public
     */
    filters: {},

    /**
     * This is set on init, based on `sort`.
     *
     * @property defaultSort
     * @type {String}
     * @protected
     */
    defaultSort: 'id',

    /**
     * This is set on init, based on `sortOrder`.
     *
     * @property defaultSortOrder
     * @type {'ASC'|'DESC'}
     * @protected
     */
    defaultSortOrder: 'ASC',

    /**
     * This is set on init, based on `filters`.
     *
     * @property defaultFilters
     * @type {Object}
     * @protected
     */
    defaultFilters: null,

    /**
     * The default page size, based on the user settings.
     *
     * @property defaultPageSize
     * @type {Number}
     * @protected
     */
    defaultPageSize: null,
    // If not set, this is initialised from user settings (and defaults to 25)

    /**
     * If there were initially either filters or items.
     * This is set by the route.
     *
     * @property isNotEmpty
     * @type {Boolean}
     * @public
     */
    isNotEmpty: false,

    /**
     * The selected items.
     *
     * @property selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * The selected items that are not deleted.
     *
     * @property activeSelectedItems
     * @type {Object[]}
     * @readOnly
     * @public
     */
    activeSelectedItems: Ember.computed.filterBy('selectedItems', 'isDeleted', false),

    /**
     * The actual page size to use.
     *
     * @property pageSize
     * @type {Number}
     * @readOnly
     * @public
     */
    pageSize: Ember.computed('pageSizeOverwrite', 'defaultPageSize', function () {
      return (Ember.get(this, 'pageSizeOverwrite') || Ember.get(this, 'defaultPageSize')) * 1;
    }),

    /**
     * If there are any filters or other settings set.
     *
     * @property hasFilters
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasFilters: Ember.computed('page', 'pageSize', 'sort', 'sortOrder', 'hasChangedFilters', 'defaultPageSize', function () {
      return Ember.get(this, 'page') !== 1 || Ember.get(this, 'pageSize') !== Ember.get(this, 'defaultPageSize') || Ember.get(this, 'sort') !== Ember.get(this, 'defaultSort') || Ember.get(this, 'sortOrder') !== Ember.get(this, 'defaultSortOrder') || Ember.get(this, 'hasChangedFilters');
    }),

    /**
     * If the filters have changed from the default filters.
     *
     * @property hasChangedFilters
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasChangedFilters: Ember.computed('filters', 'defaultFilters', function () {
      return !(0, _eqObjectContent.default)(Ember.get(this, 'filters'), Ember.get(this, 'defaultFilters'));
    }),

    /**
     * If there are any (active) selected items.
     *
     * @property hasSelectedItems
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasSelectedItems: Ember.computed.gt('activeSelectedItems.length', 0),

    /**
     * If there are any filters or selected items set.
     *
     * @property hasFiltersOrSelection
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasFiltersOrSelection: Ember.computed.or('hasFilters', 'hasSelectedItems'),

    /**
     * The data to use for the paginated table.
     * This combines all data to make it easier to pass it into the table.
     *
     * It is an object with the following properties:
     *
     * * `page`
     * * `pageSize`
     * * `sortBy`
     * * `sortOrder`
     * * `filters`
     * * `hasFiltersOrSelection`
     * * `hasChangedFilters`
     *
     * @property paginatedTableData
     * @type {Object}
     * @readOnly
     * @public
     */
    paginatedTableData: Ember.computed('page', 'pageSize', 'sort', 'sortOrder', 'filters', 'hasFiltersOrSelection', 'hasChangedFilters', function () {
      var page = Ember.get(this, 'page');
      var pageSize = Ember.get(this, 'pageSize');
      var sort = Ember.get(this, 'sort');
      var sortOrder = Ember.get(this, 'sortOrder');
      var filters = Ember.get(this, 'filters');
      var hasFiltersOrSelection = Ember.get(this, 'hasFiltersOrSelection');
      var hasChangedFilters = Ember.get(this, 'hasChangedFilters');
      return {
        page: page,
        pageSize: pageSize,
        sortBy: sort,
        sortOrder: sortOrder,
        filters: filters,
        hasFiltersOrSelection: hasFiltersOrSelection,
        hasChangedFilters: hasChangedFilters
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'filters')) {
        Ember.set(this, 'filters', {});
      }

      Ember.set(this, 'selectedItems', []);
      var sort = Ember.get(this, 'sort');
      var filters = Ember.get(this, 'filters');
      var sortOrder = Ember.get(this, 'sortOrder');
      Ember.set(this, 'defaultSort', sort);
      Ember.set(this, 'defaultFilters', filters);
      Ember.set(this, 'defaultSortOrder', sortOrder); // Check if there was a page size manually set
      // If not, try to get it from the user settings

      var userSettings = Ember.get(this, 'userSettings');
      var tableId = Ember.get(this, 'tableId');

      if (tableId && !Ember.get(this, 'defaultPageSize')) {
        var settingsTableId = "table-".concat(tableId, "-page-size");
        var defaultPageSize = userSettings.getItem(settingsTableId) || 25;
        Ember.set(this, 'defaultPageSize', defaultPageSize);
      }

      (false && !(!Ember.isBlank(Ember.get(this, 'tableId'))) && Ember.assert('You must specify a tableId on a paginated table controller', !Ember.isBlank(Ember.get(this, 'tableId'))));
    }
  });

  _exports.default = _default;
});